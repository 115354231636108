import api from '@services/_api';

export const getAllNotifications = () => {
  return async (dispatch) => {
    try {
      const { data } = await api.get('api/v1/painel/mensagens');

      dispatch({
        type: 'GET_ALL_NOTIFICACOES',
        data
      });
    } catch (error) {
      //
    }
  };
};

export const registerNotification = (notification) => {
  return async (dispatch) => {
    try {
      const { data } = await api.post(`api/v1/painel/mensagens`, notification);

      dispatch({
        type: 'ADD_NOTIFICACAO',
        data
      });
    } catch (error) {
      //
    }
  };
};

export const updateNotification = (notification) => {
  return async (dispatch) => {
    try {
      const { data } = await api.put(
        `/api/v1/painel/mensagens/${notification.id}`,
        notification
      );

      dispatch({
        type: 'UPDATE_NOTIFICACAO',
        data
      });
    } catch (err) {
      //
    }
  };
};

export const sendNotification = ({ id }) => {
  return async (dispatch) => {
    try {
      await api.post(`api/v1/painel/mensagens/${id}/send`);

      dispatch({
        type: 'UPDATE_STATUS_NOTIFICACAO',
        data: { id }
      });
    } catch (error) {
      //
    }
  };
};

export const removeNotification = ({ id }) => {
  return async (dispatch) => {
    try {
      await api.delete(`api/v1/painel/mensagens/${id}`);

      dispatch({
        type: 'REMOVE_NOTIFICACAO',
        data: { id }
      });
    } catch (error) {
      //
    }
  };
};

export const filterNotifications = (status = null) => {
  return async (dispatch) => {
    dispatch({
      type: 'FILTER_NOTIFICACOES',
      data: status
    });
  };
};
