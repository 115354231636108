// ** Redux, Thunk & Root Reducer Imports
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';
import { applyMiddleware, compose, createStore } from 'redux';

import reducer from './reducer';

// ** init middleware
const middleware = [thunk, createDebounce()];

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const preloadedState = {};

const enhancers = composeEnhancers(applyMiddleware(...middleware));

// ** Create store
export const store = createStore(reducer, preloadedState, enhancers);
