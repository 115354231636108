const initialState = {
  perguntas: [],
  categorias: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_PERGUNTA':
      return {
        ...state,
        perguntas: [...state.perguntas, action.data]
      };

    case 'UPDATE_PERGUNTA':
      return {
        ...state,
        perguntas: state.perguntas.map((pergunta) =>
          pergunta.id === action.data.id ? action.data : pergunta
        )
      };

    case 'SET_PERGUNTAS':
      return {
        ...state,
        perguntas: action.data
      };

    case 'REMOVE_PERGUNTA':
      return {
        ...state,
        perguntas: state.perguntas.filter(
          (pergunta) => pergunta.id !== action.data
        )
      };

    case 'ADD_CATEGORIA':
      return {
        ...state,
        categorias: [...state.categorias, action.data]
      };

    case 'UPDATE_CATEGORIA':
      return {
        ...state,
        categorias: state.categorias.map((categoria) =>
          categoria.id === action.data.id ? action.data : categoria
        )
      };

    case 'SET_CATEGORIAS':
      return {
        ...state,
        categorias: action.data
      };

    case 'REMOVE_CATEGORIA':
      return {
        ...state,
        categorias: state.categorias.filter(
          (categoria) => categoria.id !== action.data
        )
      };

    default:
      return state;
  }
};

export * from './actions';

export default reducer;
