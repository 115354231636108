/* eslint-disable no-console */
import { toast } from 'react-toastify';

import api, { endpoints } from '@services/_api';
import { actions } from '.';

const baseUrl = endpoints.relatorios;

const setPDF = (pdf) => (dispatch) => {
  dispatch({ type: actions.REPORT_ACTIVE_PLANS, data: { isOpen: true, pdf } });
};

export const setLoading = (loading) => {
  return { type: actions.SET_LOADING, data: { loading } };
};

export const setPeriodo = (periodo) => (dispatch) => {
  dispatch({ type: actions.SET_PERIODO, data: { periodo } });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: actions.CLOSE_MODAL_SHOW_PDF,
    data: { isOpen: false, pdf: { filename: '', src: '' } }
  });
};

export const switchReport = (report) => async (dispatch) => {
  dispatch({ type: actions.SWITCH_REPORT, data: { report } });
};

export const reportActivePlans = (payload) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const res = await api.post(`${baseUrl}/planos-ativos`, payload);
    if (res.success) dispatch(setPDF(res.data.pdf));
  } catch (error) {
    console.log(error);
    throw error;
  }
  dispatch(setLoading(false));
};

export const reportRefunds = (params) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const res = await api.get(`${baseUrl}/reembolsos`, { params });
    if (res.success) dispatch(setPDF(res.data.pdf));
  } catch (error) {
    console.log(error);
    throw error;
  }
  dispatch(setLoading(false));
};

export const financialSubscriptions = (params) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const res = await api.get(`${baseUrl}/financeiro/assinaturas`, { params });
    if (res.success) dispatch(setPDF(res.data.pdf));
  } catch (error) {
    console.log(error);
    toast.error('Não foi possível gerar o relatório');
  }
  dispatch(setLoading(false));
};

export const financialSales = (params) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const res = await api.get(`${baseUrl}/financeiro/vendas`, { params });
    if (res.success) dispatch(setPDF(res.data.pdf));
  } catch (error) {
    console.log(error);
    toast.error('Não foi possível gerar o relatório');
  }
  dispatch(setLoading(false));
};

export const financialCustomerInvoices = (id, params) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const url = `${baseUrl}/financeiro/cliente/${id}/historico-credito`;

    const res = await api.get(url, { params });

    if (res.success) dispatch(setPDF(res.data.pdf));
  } catch (error) {
    console.log(error);
    toast.error('Não foi possível gerar o relatório');
  }
  dispatch(setLoading(false));
};

export const financialCommissions = (params) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const res = await api.get('api/v1/reports/commissions', { params });
    if (res.success) dispatch(setPDF(res.data));
  } catch (error) {
    console.log(error);
    toast.error('Não foi possível gerar o relatório');
  }

  dispatch(setLoading(false));
};
