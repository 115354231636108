/* eslint-disable consistent-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/display-name */
/* eslint-disable react/require-default-props */

// ** React Imports
import { forwardRef } from 'react';

// ** Third Party Components
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import classnames from 'classnames';

const Avatar = forwardRef((props, ref) => {
  // ** Props
  const {
    color,
    className,
    imgClassName,
    initials,
    size,
    badgeUp,
    content,
    icon,
    badgeColor,
    badgeText,
    img,
    imgHeight,
    imgWidth,
    status,
    tag: Tag,
    contentStyles,
    ...rest
  } = props;

  // ** Function to extract initials from content
  const getInitials = (str) => {
    const results = [];
    const wordArray = str.split(' ');
    wordArray.forEach((e) => {
      results.push(e[0]);
    });
    return results.join('');
  };

  return (
    <Tag
      className={classnames('avatar', {
        [className]: className,
        [`bg-${color}`]: color,
        [`avatar-${size}`]: size
      })}
      ref={ref}
      {...rest}
    >
      {img === false || img === undefined ? (
        <span
          className={classnames('avatar-content', {
            'position-relative': badgeUp
          })}
          style={contentStyles}
        >
          {initials ? getInitials(content) : content}

          {icon || null}
          {badgeUp ? (
            <Badge
              color={badgeColor || 'primary'}
              className="badge-sm badge-up"
              pill
            >
              {badgeText || '0'}
            </Badge>
          ) : null}
        </span>
      ) : (
        <img
          className={classnames({
            [imgClassName]: imgClassName
          })}
          src={img}
          alt="avatarImg"
          height={imgHeight && !size ? imgHeight : 32}
          width={imgWidth && !size ? imgWidth : 32}
        />
      )}
      {status ? (
        <span
          className={classnames({
            [`avatar-status-${status}`]: status,
            [`avatar-status-${size}`]: size
          })}
        />
      ) : null}
    </Tag>
  );
});

export default Avatar;

// ** PropTypes
Avatar.propTypes = {
  imgClassName: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  badgeUp: PropTypes.bool,
  content: PropTypes.string,
  icon: PropTypes.node,
  contentStyles: PropTypes.object,
  badgeText: PropTypes.string,
  imgHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imgWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOf(['sm', 'lg', 'xl']),
  status: PropTypes.oneOf(['online', 'offline', 'away', 'busy']),
  badgeColor: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'info',
    'warning',
    'dark',
    'light-primary',
    'light-secondary',
    'light-success',
    'light-danger',
    'light-info',
    'light-warning',
    'light-dark'
  ]),
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'info',
    'warning',
    'dark',
    'light-primary',
    'light-secondary',
    'light-success',
    'light-danger',
    'light-info',
    'light-warning',
    'light-dark'
  ]),
  initials(props) {
    if (props.initials && props.content === undefined) {
      return new Error('content prop is required with initials prop.');
    }
    if (props.initials && typeof props.content !== 'string') {
      return new Error('content prop must be a string.');
    }
    if (typeof props.initials !== 'boolean' && props.initials !== undefined) {
      return new Error('initials must be a boolean!');
    }
  }
};

// ** Default Props
Avatar.defaultProps = {
  tag: 'div'
};
