import _api from '@core/services/_api';

export const getEstatisticas = () => {
  return async (dispatch) => {
    const { data } = await _api.get('api/v1/painel/estatisticas');

    dispatch({
      type: 'SET_ESTATISTICAS',
      data
    });
  };
};
