const initialState = {
  data: []
};

export const actions = {
  ALL: 'PAYMENT_METHODS_ALL'
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.ALL:
      return { ...state, data: action.data };

    default:
      return state;
  }
};

export * from './actions';

export default reducer;
