// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '');

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: 'short', day: 'numeric', year: 'numeric' }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: 'short', day: 'numeric' };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' };
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
});

/**
 *
 * @param {string} field
 * @param {Array} arrayData
 * @returns {Array}
 */
export const sortBy = (field, arrayData) => {
  return arrayData.sort((firstItem, secondItem) => {
    if (firstItem[field] < secondItem[field]) return -1;
    if (firstItem[field] > secondItem[field]) return 1;
    return 0;
  });
};

/**
 *
 * @param {Array} arrayData
 * @param {Array<string>} searchKeys
 * @param {string} search
 * @returns {Array}
 */
export const objectArraySearch = (arrayData, searchKeys, search) => {
  if (!search) return arrayData;

  if (arrayData.length === 0) return arrayData;

  if (searchKeys.length === 0) return arrayData;

  return arrayData.filter((element) => {
    const result = [];

    Object.keys(element).forEach((key) => {
      if (searchKeys.includes(key)) {
        const value = String(element[key]).toLowerCase();
        result.push(value.includes(search.toLowerCase()));
      }
    });

    return result.includes(true) ? element : null;
  });
};

/**
 *
 * @param {Array} arrayData
 * @param {String} keyLabel
 * @param {String|null} keyValue
 * @returns {Array}
 */
export const generateOptions = (arrayData, keyLabel, keyValue = null) => {
  if (!keyLabel) return arrayData;
  if (!arrayData || arrayData.length === 0) return arrayData;

  if (!keyValue)
    return arrayData.map((item) => ({ label: item[keyLabel], value: item }));

  return arrayData.map((item) => ({
    label: item[keyLabel],
    value: item[keyValue]
  }));
};

/**
 *
 * @param {number|bigint} value
 * @returns {string}
 */
export const formatBrCurrency = (value) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);
};

/**
 *
 * @param {number|bigint} value
 * @returns {string}
 */
export const numberFormatPercent = (value) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'unit',
    unit: 'percent'
  }).format(value);
};

export const fakeAsync = (callback, time) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        resolve(callback());
      } catch (error) {
        reject(error);
      }
    }, time);
  });
};
