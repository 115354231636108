// ** React Router Imports
import { Link } from 'react-router-dom';

// ** lodash
import { get } from 'lodash-es';

// ** Custom Components
import Avatar from '@core/components/avatar';

// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux';
import { handleLogout } from '@features/auth';

// ** Third Party Components
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from 'reactstrap';
import { Power } from 'react-feather';

// ** Default Avatar Image
import defaultAvatar from '@assets/images/portrait/small/profile-avatar.png';

const UserDropdown = () => {
  // ** Store Vars
  const dispatch = useDispatch();

  // ** State
  const user = useSelector((state) => state.auth.user);

  //* * Vars
  const userAvatar = (user && user.avatar) || defaultAvatar;

  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => e.preventDefault()}
      >
        <div className="user-nav d-sm-flex d-none">
          <span className="user-name font-weight-bold">
            {get(user, 'name', 'John Doe')}
          </span>
          <span className="user-status">
            {get(user, 'email', 'john_done@emil.com')}
          </span>
        </div>
        <Avatar img={userAvatar} imgHeight="40" imgWidth="40" status="online" />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem
          tag={Link}
          to="/login"
          onClick={() => dispatch(handleLogout())}
        >
          <Power size={14} className="mr-75" />
          <span className="align-middle">Encerrar sessão</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default UserDropdown;
