/* eslint-disable no-console */
import api from '@services/_api';
import { actions } from '.';

const BASE_URL = 'api/v1/painel/usuarios';

export const setIsDeleting = (isDeleting) => {
  return { type: actions.SET_IS_DELETING, data: { isDeleting } };
};

export const setIsError = (isError) => {
  return { type: actions.SET_IS_ERROR, data: { isError } };
};

export const setIsLoading = (isLoading) => {
  return { type: actions.SET_IS_LOADING, data: { isLoading } };
};

export const setIsSubmitting = (isSubmitting) => {
  return { type: actions.SET_IS_SUBMITTING, data: { isSubmitting } };
};

export const setCurrentData = (currentData) => (dispatch) => {
  dispatch({ type: actions.SET_CURRENT_DATA, data: { currentData } });
};

export const setRowsPerPage = (rowsPerPage) => (dispatch) => {
  dispatch({ type: actions.SET_ROWS_PER_PAGE, data: { rowsPerPage } });
};

export const setSearch = (search) => (dispatch) => {
  dispatch({ type: actions.SET_SEARCH, data: { search } });
};

export const getUsuarios = () => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const res = await api.get(BASE_URL);

    if (res.success) {
      dispatch({
        type: actions.GET_ALL,
        data: { isError: false, allData: res.data }
      });
    }
  } catch (error) {
    console.log(error);
    dispatch(setIsError(true));
  }
  dispatch(setIsLoading(false));
};

export const getUsuario = (id) => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const res = await api.get(`${BASE_URL}/${id}`);

    if (res.success) {
      dispatch({
        type: actions.GET_ONE_USER,
        data: { isError: false, currentData: res.data }
      });
    }
  } catch (error) {
    console.log(error);
    dispatch(setIsError(true));
  }
  dispatch(setIsLoading(false));
};

export const storeUser = (payload) => async (dispatch) => {
  dispatch(setIsSubmitting(true));
  try {
    const res = await api.post(`${BASE_URL}`, payload);

    if (res.success) {
      dispatch({
        type: actions.STORE_USER,
        data: { isError: false, newUser: res.data }
      });
    }
  } catch (error) {
    console.log(error);
    dispatch(setIsError(true));
  }
  dispatch(setIsSubmitting(false));
};

export const updateUser = (id, payload) => async (dispatch) => {
  try {
    const res = await api.put(`${BASE_URL}/${id}`, payload);

    if (res.success) {
      dispatch({ type: actions.UPDATE_USER, data: { user: res.data } });
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteUser = (user) => async (dispatch) => {
  dispatch(setIsDeleting(true));
  try {
    const res = await api.delete(`${BASE_URL}/${user.id}`);

    if (res.success) {
      dispatch({
        type: actions.DESTROY_USER,
        data: { isError: false, user: res.data }
      });
    }
  } catch (error) {
    console.log(error);
    dispatch(setIsError(true));
  }
  dispatch(setIsDeleting(false));
};

export const toggleActiveUser = (payload) => async (dispatch) => {
  try {
    const res = await api.put(`${BASE_URL}/${payload.id}/toggle`);

    if (res.success) {
      dispatch({
        type: actions.TOGGLE_ACTIVE_USER,
        data: { user: res.data }
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const changePassword = async (id, data) => {
  try {
    await api.put(`${BASE_URL}/${id}/change-password`, data);
  } catch (error) {
    console.log(error);
  }
};
