import { useCallback, useEffect } from 'react';

/**
 * @typedef DispatchEventOptions
 * @type {Object}
 * @property {boolean} bubbles
 * @property {boolean} cancelable
 * @property {boolean} composed
 */
/**
 * @param {string} name
 * @param {unknown} detail
 * @param {DispatchEventOptions=} options
 */
export function eventDispatcher(name, detail = {}, options = {}) {
  window.dispatchEvent(new CustomEvent(name, { detail, ...options }));
}

/**
 * @param {Object} params
 * @param {string} params.name
 * @param {DispatchEventOptions} params.options
 * @param {(event: CustomEvent) => void} params.handler
 */
export function useEvent({ name, options = {}, handler }) {
  const dispatch = useCallback(
    /** @param {unknown} data */
    (data) => eventDispatcher(name, data, options),
    [name, options]
  );

  useEffect(() => {
    window?.addEventListener(name, handler);

    return () => {
      window?.removeEventListener(name, handler);
    };
  }, [name, handler]);

  return { dispatch };
}
