const initialState = {
  data: [],

  stepper: null,

  isLoading: false,
  isCanceling: false,
  isSubmitting: false,

  formData: {},
  currentData: {},

  modalPix: { isOpen: false, data: {} },
  payHistory: { isOpen: false, data: {} },

  requests: { show: false, cancel: { status: '', isLoading: false } },
  sending: { active: false, message: '' }
};

/**
 * @typedef InsuranceState
 * @type {typeof initialState}
 */

/**
 * @typedef InsuranceAction
 * @type {(state: InsuranceState, action: { type: string, data?: unknown }) => InsuranceState}
 */

const actions = {
  /** @type {InsuranceAction} */
  fetchInsurances: (state, { data }) => ({ ...state, data, isLoading: false }),
  /** @type {InsuranceAction} */
  setLoading: (state, { data }) => ({ ...state, isLoading: data }),
  /** @type {InsuranceAction} */
  setCurrentData: (state, { data }) => ({ ...state, currentData: data }),
  /** @type {InsuranceAction} */
  setFormData: (state, { data }) => ({ ...state, formData: data }),
  /** @type {InsuranceAction} */
  setCanceling: (state, { data: { status, isLoading } }) => ({
    ...state,
    requests: { ...state.requests, cancel: { status, isLoading } }
  }),
  /** @type {InsuranceAction} */
  setShowing: (state, { data }) => ({
    ...state,
    requests: { ...state.requests, show: data }
  }),
  /** @type {InsuranceAction} */
  getInsurance: (state, { data }) => ({
    ...state,
    currentData: data,
    requests: { ...state.requests, show: false }
  }),
  /** @type {InsuranceAction} */
  setIsSubmitting: (state, { data }) => ({ ...state, isSubmitting: data }),
  /** @type {InsuranceAction} */
  setModalPix: (state, { data }) => ({ ...state, modalPix: data }),
  /** @type {InsuranceAction} */
  setStepper: (state, { data }) => ({ ...state, stepper: data }),
  /** @type {InsuranceAction} */
  setSending: (state, { data }) => ({ ...state, sending: data }),
  /** @type {InsuranceAction} */
  addOne: (state, { data: { data, ...rest } }) => {
    /** @type {InsuranceState} */
    const newState = { ...state, ...rest };

    newState.data = [...state.data, data];

    return newState;
  },
  /** @type {InsuranceAction} */
  deleteInsurance: (state, { data: { id } }) => {
    /** @type {InsuranceState} */
    const newState = { ...state, currentData: {} };

    newState.data = newState.data.filter((item) => item.id !== id);

    return newState;
  },
  /** @type {InsuranceAction} */
  updateOne: (state, { data: { data, ...rest } }) => {
    /** @type {InsuranceState} */
    const newState = { ...state, ...rest };

    newState.data = newState.data.map((item) =>
      item.id === data.id ? { ...item, ...data } : item
    );

    return newState;
  },
  /** @type {InsuranceAction} */
  resetState: (state, { data: resettableProps }) => {
    const newState = { ...state };

    resettableProps.forEach((property) => {
      newState[property] = initialState[property];
    });

    return newState;
  }
};

/**
 * @typedef InsuranceActionType
 * @type {keyof typeof actions}
 */

/**
 * @typedef InsuranceDispatcher
 * @type {(params: { type: InsuranceActionType, data: Partial<InsuranceState> }) => void}
 */

/**
 *
 * @param {InsuranceState} state
 * @param {Object} action
 * @param {InsuranceActionType} action.type
 * @param {Partial<InsuranceState>} action.data
 * @returns {InsuranceState}
 */
export default (state = initialState, action = null) => {
  if (action.type in actions) {
    return actions[action.type](state, action);
  }

  return state;
};

export * from './actions';
