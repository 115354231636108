/* eslint-disable no-console */
import api from '@services/_api';
import { actions } from '.';

const BASE_URL = 'api/v1/painel';

export const setIsDeleting = (isDeleting) => {
  return { type: actions.SET_IS_DELETING, data: { isDeleting } };
};

export const setIsError = (isError) => {
  return { type: actions.SET_IS_ERROR, data: { isError } };
};

export const setIsLoading = (isLoading) => {
  return { type: actions.SET_IS_LOADING, data: { isLoading } };
};

export const setIsSubmitting = (isSubmitting) => {
  return { type: actions.SET_IS_SUBMITTING, data: { isSubmitting } };
};

export const setCurrentData = (currentData) => (dispatch) => {
  dispatch({ type: actions.SET_CURRENT_DATA, data: { currentData } });
};

export const setRowsPerPage = (rowsPerPage) => (dispatch) => {
  dispatch({ type: actions.SET_ROWS_PER_PAGE, data: { rowsPerPage } });
};

export const setSearch = (search) => (dispatch) => {
  dispatch({ type: actions.SET_SEARCH, data: { search } });
};

/**
 *
 * @param {Boolean} isOpen
 * @param {'create'|'edit'|'delete'|'close'} action
 * @param {CupomModel} currentData
 */
export const toggleModal = (isOpen, action = 'create', currentData = {}) => {
  return (dispatch) => {
    dispatch({
      type: actions.TOGGLE_MODAL,
      data: { currentData, openModal: { isOpen, action } }
    });
  };
};

export const getCupons = () => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const res = await api.get(`${BASE_URL}/cupons`);

    if (res.success) {
      dispatch({
        type: actions.GET_ALL,
        data: { isError: false, allData: res.data }
      });
    }
  } catch (error) {
    console.log(error);
    dispatch(setIsError(true));
  }
  dispatch(setIsLoading(false));
};

export const storeCupom = (payload) => async (dispatch) => {
  dispatch(setIsSubmitting(true));
  try {
    const res = await api.post(`${BASE_URL}/cupons`, payload);
    if (res.success) {
      dispatch({
        type: actions.STORE_CUPOM,
        data: {
          isError: false,
          newCupom: res.data,
          openModal: { isOpen: false, action: 'close' }
        }
      });
    }
  } catch (error) {
    console.log(error);
    dispatch(setIsError(true));
  }
  dispatch(setIsSubmitting(false));
};

export const updateCupom = (id, payload) => async (dispatch) => {
  dispatch(setIsSubmitting(true));
  try {
    const res = await api.put(`${BASE_URL}/cupons/${id}`, payload);
    if (res.success) {
      dispatch({
        type: actions.UPDATE_CUPOM,
        data: {
          isError: false,
          cupom: res.data,
          currentData: {},
          openModal: { isOpen: false, action: 'close' }
        }
      });
    }
  } catch (error) {
    console.log(error);
    dispatch(setIsError(true));
  }
  dispatch(setIsSubmitting(false));
};

export const changeStatusCupom = (id, payload) => async (dispatch) => {
  try {
    const res = await api.put(
      `${BASE_URL}/cupons/${id}/change-status`,
      payload
    );

    if (res.success) {
      dispatch({
        type: actions.CHANGE_STATUS_CUPOM,
        data: {
          isError: false,
          cupom: res.data,
          currentData: {},
          openModal: { isOpen: false, action: 'close' }
        }
      });
    }
  } catch (error) {
    console.log(error);
    dispatch(setIsError(true));
  }
  dispatch(setIsSubmitting(false));
};

export const deleteCupom = (cupom) => async (dispatch) => {
  dispatch(setIsDeleting(true));
  try {
    const res = await api.delete(`${BASE_URL}/cupons/${cupom.id}`);
    if (res.success) {
      dispatch({
        type: actions.DELETE_CUPOM,
        data: {
          cupom,
          isError: false,
          currentData: {},
          openModal: { isOpen: false, action: 'close' }
        }
      });
    }
  } catch (error) {
    console.log(error);
    dispatch(setIsError(true));
  }
  dispatch(setIsDeleting(false));
};
