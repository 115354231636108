import { get } from 'lodash-es';

import { objectArraySearch } from '@src/utils';

const initialState = {
  memorias: [],
  coberturas: [],
  marcas: [],
  pacotes: [],

  modelos: [],
  queryModelos: '',

  filteredModelos: [],
  aparelhos: [],

  planos: [],
  filteredPlanos: [],
  queryPlanos: '',

  loading: {
    planos: true
  },

  persist: {
    marcas: null,
    modelos: null,
    memorias: null,
    aparelhos: null
  },

  rowsPerPage: 20
};

const filterPlanos = (planos, filter) => {
  const showAll = (f) =>
    f === null || (Array.isArray(f) ? f.length === 0 : false);

  const filteredByMarca = planos.filter((plano) =>
    showAll(filter.marcas) ? true : filter.marcas.includes(plano.marca)
  );
  const filteredByModelo = filteredByMarca.filter((plano) =>
    showAll(filter.modelos) ? true : filter.modelos.includes(plano.modelo)
  );
  const filteredByMemoria = filteredByModelo.filter((plano) =>
    showAll(filter.memorias)
      ? true
      : filter.memorias.includes(plano.memorias_id)
  );
  const filteredByAparelho = filteredByMemoria.filter((plano) =>
    showAll(filter.aparelhos)
      ? true
      : filter.aparelhos.includes(plano.aparelhos_id)
  );

  return filteredByAparelho;
};

const searchPlanos = (planos, query) => {
  const searchKeys = ['titulo', 'marca', 'modelo', 'aparelho', 'memoria'];
  return objectArraySearch(planos, searchKeys, query);
};

const filterModelos = (state, query = state.queryModelos) => {
  return {
    ...state,
    queryModelos: query,
    filteredModelos: objectArraySearch(state.modelos, ['nome'], query)
  };
};

const loadRelatedFields = (state) => {
  const newPlanos = state.planos.map((plano) => {
    const aparelho = state.aparelhos.find(
      (aparelho) => aparelho.id === get(plano, 'aparelhos_id', undefined)
    );
    const modelo = state.modelos.find(
      (modelo) => modelo.id === get(aparelho, 'modelos_id', undefined)
    );
    const marca = state.marcas.find(
      (marca) => marca.id === get(modelo, 'marcas_id', undefined)
    );
    const memoria = state.memorias.find(
      (memoria) => memoria.id === get(plano, 'memorias_id', undefined)
    );

    return {
      ...plano,
      modelo: get(modelo, 'nome', undefined),
      marca: get(marca, 'nome', undefined),
      memoria: get(memoria, 'nome', undefined),
      aparelho: get(aparelho, 'nome', undefined)
    };
  });

  return {
    ...state,
    planos: newPlanos,
    filteredPlanos: searchPlanos(newPlanos, state.queryPlanos)
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // ** Memórias
    case 'SET_MEMORIAS':
      return {
        ...state,
        memorias: action.data
      };

    case 'ADD_MEMORIA':
      return {
        ...state,
        memorias: [...state.memorias, action.data]
      };

    case 'REMOVE_MEMORIA':
      return {
        ...state,
        memorias: state.memorias.filter(
          (memoria) => memoria.id !== Number(action.data)
        )
      };

    case 'UPDATE_MEMORIA':
      return {
        ...state,
        memorias: state.memorias.map((memoria) =>
          memoria.id === action.data.id ? action.data : memoria
        )
      };
    // ** Fim memórias

    // ** Coberturas
    case 'SET_COBERTURAS':
      return {
        ...state,
        coberturas: action.data
      };

    case 'ADD_COBERTURA':
      return {
        ...state,
        coberturas: [...state.coberturas, action.data]
      };

    case 'REMOVE_COBERTURA':
      return {
        ...state,
        coberturas: state.coberturas.filter(
          (cobertura) => cobertura.id !== Number(action.data)
        )
      };

    case 'UPDATE_COBERTURA':
      return {
        ...state,
        coberturas: state.coberturas.map((cobertura) =>
          cobertura.id === action.data.id ? action.data : cobertura
        )
      };
    // ** Fim cobertura

    // ** Pacotes
    case 'ADD_PACOTE':
      return {
        ...state,
        pacotes: [...state.pacote, action.data]
      };

    case 'SET_PACOTES':
      return {
        ...state,
        pacotes: action.data
      };
    // ** Fim pacotes

    // ** Marcas
    case 'ADD_MARCA':
      return {
        ...state,
        marcas: [...state.marcas, action.data].sort((a, b) =>
          a.nome.localeCompare(b.nome)
        )
      };

    case 'SET_MARCAS':
      return {
        ...state,
        marcas: action.data.sort((a, b) => a.nome.localeCompare(b.nome))
      };

    case 'REMOVE_MARCA':
      return {
        ...state,
        marcas: state.marcas.filter((marca) => marca.id !== Number(action.data))
      };

    case 'UPDATE_MARCA':
      return {
        ...state,
        marcas: state.marcas
          .map((marcas) =>
            marcas.id === action.data.id ? action.data : marcas
          )
          .sort((a, b) => a.nome.localeCompare(b.nome))
      };
    // ** Fim marcas

    // ** Modelos
    case 'SET_MODELOS':
      return filterModelos({
        ...state,
        modelos: action.data
      });

    case 'ADD_MODELO':
      return filterModelos({
        ...state,
        modelos: [...state.modelos, action.data]
      });

    case 'UPDATE_MODELO':
      return filterModelos({
        ...state,
        modelos: state.modelos.map((modelo) =>
          modelo.id === action.data.id ? action.data : modelo
        )
      });

    case 'REMOVE_MODELO':
      return filterModelos({
        ...state,
        modelos: state.modelos.filter(
          (modelo) => modelo.id !== Number(action.data)
        )
      });

    case 'SEARCH_MODELOS':
      return filterModelos(state, action.data);

    // ** Fim Modelos

    // ** Aparelhos
    case 'SET_APARELHOS':
      return {
        ...state,
        aparelhos: action.data
      };

    case 'ADD_APARELHO':
      return {
        ...state,
        aparelhos: [...state.aparelhos, action.data]
      };

    case 'UPDATE_APARELHO':
      return {
        ...state,
        aparelhos: state.aparelhos.map((aparelho) =>
          aparelho.id === action.data.id ? action.data : aparelho
        )
      };

    case 'REMOVE_APARELHO':
      return {
        ...state,
        aparelhos: state.aparelhos.filter(
          (aparelho) => aparelho.id !== Number(action.data)
        )
      };
    // ** Fim Aparelhos

    // ** Planos
    case 'SET_PLANOS': {
      const newState = loadRelatedFields({
        ...state,
        planos: action.data,
        filteredPlanos: action.data
      });

      return {
        ...newState,
        loading: {
          ...newState,
          planos: false
        }
      };
    }

    case 'SEARCH_PLANOS':
      return {
        ...state,
        queryPlanos: action.data,
        filteredPlanos: searchPlanos(state.planos, action.data)
      };

    case 'FILTER_PLANOS':
      return {
        ...state,
        persist: action.data.persist,
        filteredPlanos: filterPlanos(state.planos, action.data.filter)
      };

    case 'ADD_PLANO':
      return loadRelatedFields({
        ...state,
        planos: [...state.planos, action.data]
      });

    case 'UPDATE_PLANO':
      return loadRelatedFields({
        ...state,
        planos: state.planos.map((plano) =>
          plano.id === action.data.id ? action.data : plano
        )
      });

    case 'REMOVE_PLANO':
      return loadRelatedFields({
        ...state,
        planos: state.planos.filter((plano) => plano.id !== Number(action.data))
      });

    case 'PLANOS_SET_ROWS_PER_PAGE':
      return { ...state, ...action.data };
    // ** Fim planos

    default:
      return state;
  }
};

export * from './actions';

export default reducer;
