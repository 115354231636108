// ** Logo
import themeConfig from '@configs/themeConfig';

const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner vh-100">
      <img
        alt="logo"
        width="10%"
        className="fallback-logo"
        src={themeConfig.app.appLogoImageFull}
      />
      <div className="loading">
        <div className="effect-1 effects" />
        <div className="effect-2 effects" />
        <div className="effect-3 effects" />
      </div>
    </div>
  );
};

export default SpinnerComponent;
