const apiConfig = {
  apiURL: process.env.REACT_APP_HOST,

  tokenType: 'Bearer',

  storageUserKeyName: '@DSAuth:USER',
  storageTokenKeyName: '@DSAuth:TOKEN',
  storageRefreshTokenKeyName: '@DSAuth:REFRESH-TOKEN',

  endpoints: {
    login: 'api/v1/painel/login',
    refreshToken: 'api/v1/painel/refresh-token',

    ajuda: 'api/v1/painel/ajuda',
    ajudaCategorias: 'api/v1/painel/ajuda-categorias',
    aparelhos: 'api/v1/painel/aparelhos',
    clientes: 'api/v1/painel/clientes',
    coberturas: 'api/v1/painel/coberturas',
    cupons: 'api/v1/painel/cupons',
    destaques: 'api/v1/painel/destaques',
    empresas: 'api/v1/painel/empresas',
    marcas: 'api/v1/painel/marcas',
    memorias: 'api/v1/painel/memorias',
    mensagens: 'api/v1/painel/mensagens',
    modelos: 'api/v1/painel/modelos',
    planos: 'api/v1/painel/planos',
    reembolsos: 'api/v1/painel/reembolsos',
    relatorios: 'api/v1/painel/relatorios',
    seguros: 'api/v1/painel/seguros',
    representantes: 'api/v1/painel/representantes',
    usuarios: 'api/v1/painel/usuarios',
    vendedores: 'api/v1/painel/vendedores'
  }
};

export default apiConfig;
