// ** React Import
import { useEffect, createContext } from 'react';

// ** Actions Import
import { setUser } from '@features/auth';

// ** Redux Import
import { useDispatch } from 'react-redux';

// ** Utils Import
import { isUserLoggedIn, getUserData } from '@utils/authUtils';

const AuthContect = createContext();

export function AuthProvider({ children }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isUserLoggedIn()) dispatch(setUser(getUserData(), true));
  }, [dispatch]);

  return <AuthContect.Provider value={{}}>{children}</AuthContect.Provider>;
}
