import { lazy } from 'react';

// ** Document title
const TemplateTitle = '%s - Vuexy React Admin Template';

// ** Default Route
const DefaultRoute = '/home';

// ** Merge Routes
const Routes = [
  {
    exact: true,
    path: '/home',
    component: lazy(() => import('@src/views/home'))
  },
  {
    exact: true,
    path: '/login',
    layout: 'BlankLayout',
    meta: { publicRoute: true },
    component: lazy(() => import('@src/views/auth/login'))
  },
  {
    exact: true,
    path: '/error',
    layout: 'BlankLayout',
    meta: { publicRoute: true },
    component: lazy(() => import('@src/views/error'))
  },
  {
    exact: true,
    path: '/clientes',
    component: lazy(() => import('@src/views/customers'))
  },
  {
    exact: true,
    path: '/clientes/detalhes',
    component: lazy(() => import('@src/views/customers/details'))
  },
  {
    exact: true,
    path: '/cupons',
    component: lazy(() => import('@src/views/coupons'))
  },
  {
    exact: true,
    path: '/destaques',
    component: lazy(() => import('@src/views/featured'))
  },
  {
    exact: true,
    path: '/usuarios',
    component: lazy(() => import('@src/views/users'))
  },
  {
    exact: true,
    path: '/usuarios/novo-usuario',
    component: lazy(() => import('@src/views/users/create'))
  },
  {
    exact: true,
    path: '/usuarios/editar',
    component: lazy(() => import('@src/views/users/details'))
  },
  {
    exact: true,
    path: '/planos',
    component: lazy(() => import('@src/views/plans'))
  },
  {
    exact: true,
    path: '/planos/cobertura',
    component: lazy(() => import('@src/views/plans/coverages'))
  },
  {
    exact: true,
    path: '/planos/memoria',
    component: lazy(() => import('@src/views/plans/memoirs'))
  },
  {
    exact: true,
    path: '/planos/modelos',
    component: lazy(() => import('@src/views/plans/device-models'))
  },
  {
    exact: true,
    path: '/planos/aparelho/:id',
    component: lazy(() => import('@src/views/plans/devices'))
  },
  {
    exact: true,
    path: '/notificacoes',
    component: lazy(() => import('@src/views/notifications'))
  },
  {
    exact: true,
    path: '/representantes',
    component: lazy(() => import('@src/views/representatives'))
  },
  {
    exact: true,
    path: '/empresas',
    component: lazy(() => import('@src/views/companies'))
  },
  {
    exact: true,
    path: '/vendedores',
    component: lazy(() => import('@src/views/sellers'))
  },
  {
    path: '/ajuda',
    component: lazy(() => import('@src/views/help'))
  },
  {
    exact: true,
    path: '/reembolsos',
    component: lazy(() => import('@src/views/refunds'))
  },
  {
    exact: true,
    path: '/reembolsos/visualizar',
    component: lazy(() => import('@src/views/refunds/details'))
  },
  {
    exact: true,
    path: '/relatorios',
    component: lazy(() => import('@src/views/reports'))
  },
  {
    path: '/protecao',
    exact: true,
    component: lazy(() => import('@src/views/insurances'))
  },
  {
    exact: true,
    path: '/protecao/visualizar',
    component: lazy(() => import('@src/views/insurances/details'))
  }
];

export { DefaultRoute, TemplateTitle, Routes };
