const initialState = {
  data: [],
  filterData: [],
  filter: null
};

const filterNotificacoesByStatus = (notificacoes, status) => {
  if (status == null) {
    return notificacoes;
  }

  return notificacoes.filter((notificacao) => notificacao.status === status);
};

const updateStatus = (data, state) => {
  const newData = state.data.map((notification) => {
    if (notification.id === data.id) {
      return {
        ...notification,
        ...{ status: 1 }
      };
    }

    return notification;
  });

  return newData;
};

const updateNotification = (updatedData, state) => {
  const newData = state.data.map((notification) => {
    if (notification.id === updatedData.id) {
      return updatedData;
    }

    return notification;
  });

  return newData;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_NOTIFICACOES':
      return {
        ...state,
        data: action.data,
        filterData: filterNotificacoesByStatus(action.data, state.filter)
      };

    case 'ADD_NOTIFICACAO': {
      const newData = { ...action.data, status: 0 };
      return {
        ...state,
        data: [...state.data, newData],
        filterData: filterNotificacoesByStatus(
          [newData, ...state.data],
          state.filter
        )
      };
    }

    case 'UPDATE_NOTIFICACAO': {
      return {
        ...state,
        data: updateNotification(action.data, state),
        filterData: updateNotification(action.data, state)
      };
    }

    case 'REMOVE_NOTIFICACAO':
      return {
        ...state,
        data: state.data.filter(
          (notification) => notification.id !== action.data.id
        ),
        filterData: state.data.filter(
          (notification) => notification.id !== action.data.id
        )
      };

    case 'UPDATE_STATUS_NOTIFICACAO': {
      return {
        ...state,
        data: updateStatus(action.data, state),
        filterData: updateStatus(action.data, state)
      };
    }

    case 'FILTER_NOTIFICACOES':
      return {
        ...state,
        filter: action.data,
        filterData: filterNotificacoesByStatus(state.data, action.data)
      };

    default:
      return { ...state };
  }
};

export * from './actions';

export default reducer;
