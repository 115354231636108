// **  Initial State
const initialState = {
  user: {},
  isError: false,
  isSubmitting: null,
  isAuthenticated: false
};

export const actions = {
  LOGIN: 'AUTH_LOGIN',
  LOGOUT: 'AUTH_LOGOUT',
  SET_IS_ERROR: 'AUTH_SET_IS_ERROR',
  SET_IS_SUBMITTING: 'AUTH_SET_IS_SUBMITTING',
  SET_USER: 'AUTH_SET_USER'
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOGIN:
      return { ...state, ...action.data };
    case actions.LOGOUT:
      return { ...state, ...initialState };
    case actions.SET_IS_ERROR:
      return { ...state, ...action.data };
    case actions.SET_IS_SUBMITTING:
      return { ...state, ...action.data };
    case actions.SET_USER:
      return { ...state, ...action.data };
    default:
      return state;
  }
};

export * from './actions';

export default reducer;
