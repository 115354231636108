import api from '@services/_api';

// ** Categorias
export const getAllCategoriaAjuda = () => {
  return async (dispatch) => {
    const { data: result } = await api.get('/api/v1/painel/ajuda-categorias');

    dispatch({
      type: 'SET_CATEGORIAS',
      data: result
    });
  };
};

export const createCategoriaAjuda = (data) => {
  return async (dispatch) => {
    const { data: result } = await api.post(
      '/api/v1/painel/ajuda-categorias',
      data
    );

    dispatch({
      type: 'ADD_CATEGORIA',
      data: result
    });
  };
};

export const deleteCategoriaAjuda = (id) => {
  return async (dispatch) => {
    await api.delete(`/api/v1/painel/ajuda-categorias/${id}`);

    dispatch({
      type: 'REMOVE_CATEGORIA',
      data: id
    });
  };
};

export const editCategoriaAjuda = (data) => {
  return async (dispatch) => {
    const { data: result } = await api.put(
      `/api/v1/painel/ajuda-categorias/${data.id}`,
      data
    );

    dispatch({
      type: 'UPDATE_CATEGORIA',
      data: result
    });
  };
};

// ** Perguntas
export const getAllPerguntas = () => {
  return async (dispatch) => {
    const { data: result } = await api.get('/api/v1/painel/ajuda');

    dispatch({
      type: 'SET_PERGUNTAS',
      data: result
    });
  };
};

export const createPergunta = (data) => {
  return async (dispatch) => {
    const { data: result } = await api.post('/api/v1/painel/ajuda', data);

    dispatch({
      type: 'ADD_PERGUNTA',
      data: result
    });
  };
};

export const editPergunta = (data) => {
  return async (dispatch) => {
    const { data: result } = await api.put(
      `/api/v1/painel/ajuda/${data.id}`,
      data
    );

    dispatch({
      type: 'UPDATE_PERGUNTA',
      data: result
    });
  };
};

export const deletePergunta = (id) => {
  return async (dispatch) => {
    await api.delete(`/api/v1/painel/ajuda/${id}`);

    dispatch({
      type: 'REMOVE_PERGUNTA',
      data: id
    });
  };
};
