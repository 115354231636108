import { sortBy } from '@src/utils';

const initialUsers = {
  rowsPerPage: 10,

  search: '',

  currentData: {},

  isDeleting: false,
  isError: false,
  isLoading: false,
  isSubmitting: false,

  allData: []
};

export const actions = {
  DESTROY_USER: 'USERS_DESTROY_USER',

  GET_ALL: 'USERS_GET_ALL',
  GET_ONE_USER: 'USERS_GET_ONE_USER',

  SET_CURRENT_DATA: 'USERS_SET_CURRENT_DATA',

  SET_IS_DELETING: 'USERS_SET_IS_DELETING',
  SET_IS_ERROR: 'USERS_SET_IS_ERROR',
  SET_IS_LOADING: 'USERS_SET_IS_LOADING',
  SET_IS_SUBMITTING: 'USERS_SET_IS_SUBMITTING',

  SET_ROWS_PER_PAGE: 'USERS_SET_ROWS_PER_PAGE',
  SET_SEARCH: 'USERS_SET_SEARCH',

  STORE_USER: 'USERS_STORE_USER',

  TOGGLE_ACTIVE_USER: 'USERS_TOGGLE_ACTIVE_USER',

  UPDATE_USER: 'USERS_UPDATE_USER'
};

const storeUser = (state, action) => {
  const newState = state;
  const { isError, newUser } = action.data;

  newState.isError = isError;
  newState.allData.push(newUser);

  /* Ordenar os usuarios pelo nome */
  newState.allData = sortBy('name', newState.allData);

  return newState;
};

const updateUser = (state, action) => {
  const newState = state;
  const { isError, user } = action.data;

  newState.isError = isError;
  newState.currentData = user;

  newState.allData.find((item) =>
    item.id === user.id ? Object.assign(item, { ...user }) : item
  );

  return newState;
};

const deleteUser = (state, action) => {
  const newState = state;
  const { user } = action.data;

  const index = newState.allData.findIndex(({ id }) => id === user.id);

  newState.allData.splice(index, 1);

  return newState;
};

const toggleUser = (state, action) => {
  const newState = state;
  const { user } = action;

  newState.currentData = user;

  newState.allData.find((item) =>
    item.id === user.id ? Object.assign(item, { ...user }) : item
  );

  return newState;
};

const reducer = (state = initialUsers, action) => {
  switch (action.type) {
    case actions.GET_ALL:
      return { ...state, ...action.data };

    case actions.GET_ONE_USER:
      return { ...state, ...action.data };

    case actions.SET_CURRENT_DATA:
      return { ...state, ...action.data };

    case actions.SET_IS_DELETING:
      return { ...state, ...action.data };

    case actions.SET_IS_ERROR:
      return { ...state, ...action.data };

    case actions.SET_IS_LOADING:
      return { ...state, ...action.data };

    case actions.SET_IS_SUBMITTING:
      return { ...state, ...action.data };

    case actions.SET_ROWS_PER_PAGE:
      return { ...state, ...action.data };

    case actions.SET_SEARCH:
      return { ...state, ...action.data };

    case actions.STORE_USER:
      return storeUser(state, action);

    case actions.UPDATE_USER:
      return updateUser(state, action);

    case actions.DESTROY_USER:
      return deleteUser(state, action);

    case actions.TOGGLE_ACTIVE_USER:
      return toggleUser(state, action);

    default:
      return state;
  }
};

export * from './actions';

export default reducer;
