const initialState = {
  rowsPerPage: 10,

  search: '',

  currentData: {},
  openModal: {
    isOpen: false,
    action: 'create'
  },

  isDeleting: false,
  isError: false,
  isLoading: false,
  isSubmitting: false,

  allData: []
};

const initialAction = {
  type: null,
  data: {}
};

export const actions = {
  GET_ALL: 'REEMBOLSOS_GET_ALL',
  GET_ONE: 'REEMBOLSOS_GET_ONE',

  SET_CURRENT_DATA: 'REEMBOLSOS_SET_CURRENT_DATA',

  SET_IS_DELETING: 'REEMBOLSOS_SET_IS_DELETING',
  SET_IS_ERROR: 'REEMBOLSOS_SET_IS_ERROR',
  SET_IS_LOADING: 'REEMBOLSOS_SET_IS_LOADING',
  SET_IS_SUBMITTING: 'REEMBOLSOS_SET_IS_SUBMITTING',

  SET_ROWS_PER_PAGE: 'REEMBOLSOS_SET_ROWS_PER_PAGE',
  SET_SEARCH: 'REEMBOLSOS_SET_SEARCH',

  STORE: 'REEMBOLSOS_STORE',
  UPDATE: 'REEMBOLSOS_UPDATE',
  DELETE: 'REEMBOLSOS_DELETE',

  TOGGLE_MODAL: 'REEMBOLSOS_TOGGLE_MODAL'
};

const storeReembolso = (state = initialState, action = initialAction) => {
  const { newReembolso, ...rest } = action.data;

  const newState = { ...state, ...rest };

  newState.allData.push(newReembolso);

  return newState;
};

const updateReembolso = (state = initialState, action = initialAction) => {
  const { data, ...rest } = action.data;
  const newState = { ...state, ...rest };

  newState.allData = newState.allData.map((item) => {
    return item.id === data.id ? { ...item, ...data } : item;
  });

  return newState;
};

const deleteReembolso = (state = initialState, action = initialAction) => {
  const { data, ...rest } = action.data;
  const newState = { ...state, ...rest };

  const index = newState.allData.findIndex(({ id }) => id === data.id);

  newState.allData.splice(index, 1);

  return newState;
};

const reducer = (state = initialState, action = initialAction) => {
  switch (action.type) {
    case actions.GET_ALL:
      return { ...state, ...action.data };

    case actions.GET_ONE:
      return { ...state, ...action.data };

    case actions.SET_CURRENT_DATA:
      return { ...state, ...action.data };

    case actions.SET_IS_DELETING:
      return { ...state, ...action.data };

    case actions.SET_IS_ERROR:
      return { ...state, ...action.data };

    case actions.SET_IS_LOADING:
      return { ...state, ...action.data };

    case actions.SET_IS_SUBMITTING:
      return { ...state, ...action.data };

    case actions.SET_ROWS_PER_PAGE:
      return { ...state, ...action.data };

    case actions.SET_SEARCH:
      return { ...state, ...action.data };

    case actions.STORE:
      return storeReembolso(state, action);

    case actions.UPDATE:
      return updateReembolso(state, action);

    case actions.DELETE:
      return deleteReembolso(state, action);

    case actions.TOGGLE_MODAL:
      return { ...state, ...action.data };

    default:
      return state;
  }
};

export * from './actions';

export default reducer;
