// You can customize the template with the help of this file

import appLogoImage from '@assets/images/logo/logo.png';
import appLogoDarkImage from '@assets/images/logo/logo-dark.png';
import appLogoImageFull from '@assets/images/logo/digi-save-full.png';

// Template config options
const themeConfig = {
  app: {
    appName: 'DigiSave',
    appVersion: process.env.REACT_APP_VERSION,
    appLogoImage,
    appLogoImageFull,
    appLogoDarkImage
  },
  layout: {
    isRTL: false,
    skin: 'dark', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: '' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  }
};

export default themeConfig;
