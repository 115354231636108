const initialState = {
  loading: false,
  periodo: {
    start_date: '',
    end_date: ''
  },
  pdf: {
    filename: '',
    src: ''
  },
  report: {
    label: '',
    value: null
  },
  isOpen: false
};

export const actions = {
  SET_LOADING: 'REPORT_SET_LOADING',
  SWITCH_REPORT: 'REPORT_SWITCH_REPORT',
  SET_PERIODO: 'REPORTS_SET_PERIODO',
  OPEN_MODAL_SHOW_PDF: 'REPORT_OPEN_MODAL_SHOW_PDF',
  CLOSE_MODAL_SHOW_PDF: 'REPORT_CLOSE_MODAL_SHOW_PDF',
  REPORT_ACTIVE_PLANS: 'REPORTS_ACTIVE_PLANS'
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CLOSE_MODAL_SHOW_PDF:
      return { ...state, ...action.data };
    case actions.OPEN_MODAL_SHOW_PDF:
      return { ...state, ...action.data };
    case actions.REPORT_ACTIVE_PLANS:
      return { ...state, ...action.data };
    case actions.SET_LOADING:
      return { ...state, ...action.data };
    case actions.SET_PERIODO:
      return { ...state, ...action.data };
    case actions.SWITCH_REPORT:
      return { ...state, ...action.data };
    default:
      return state;
  }
};

export * from './actions';

export default reducer;
