const initialState = {
  data: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DESTAQUES':
      return {
        ...state,
        data: action.data
      };

    case 'ADD_DESTAQUE':
      return {
        ...state,
        data: [...state.data, action.data]
      };

    case 'UPDATE_DESTAQUE':
      return {
        ...state,
        data: state.data.map((destaque) =>
          destaque.id === action.data.id ? action.data : destaque
        )
      };

    case 'DELETE_DESTAQUE':
      return {
        ...state,
        data: state.data.filter((destaque) => destaque.id !== action.data)
      };

    default:
      return state;
  }
};

export * from './actions';

export default reducer;
