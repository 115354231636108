import api, { endpoints } from '@services/_api';
import { actions } from '.';

export const setIsDeleting = (isDeleting) => {
  return { type: actions.SET_IS_DELETING, data: { isDeleting } };
};

export const setIsError = (isError) => {
  return { type: actions.SET_IS_ERROR, data: { isError } };
};

export const setIsLoading = (isLoading) => {
  return { type: actions.SET_IS_LOADING, data: { isLoading } };
};

export const setIsSubmitting = (isSubmitting) => {
  return { type: actions.SET_IS_SUBMITTING, data: { isSubmitting } };
};

export const setCurrentData = (currentData) => (dispatch) => {
  dispatch({ type: actions.SET_CURRENT_DATA, data: { currentData } });
};

export const setRowsPerPage = (rowsPerPage) => (dispatch) => {
  dispatch({ type: actions.SET_ROWS_PER_PAGE, data: { rowsPerPage } });
};

export const setSearch = (search) => (dispatch) => {
  dispatch({ type: actions.SET_SEARCH, data: { search } });
};

export const setStepper = (stepper) => (dispatch) => {
  dispatch({ type: actions.SET_STEPPER, data: { stepper } });
};

/**
 *
 * @param {Boolean} isLoading
 * @param {'representantes'|'empresas'|'vendedores'} optionType
 */
export const setLoadingOptions = (isLoading, optionType) => ({
  type: actions.SET_IS_LOADING,
  data: { loadingOptions: { isLoading, optionType } }
});

/**
 *
 * @param {Boolean} isOpen
 * @param {'create'|'edit'|'delete'|'close'} action
 * @param {RepresentanteModel} currentData
 */
export const toggleModal = (isOpen, action = 'create', currentData = {}) => {
  return (dispatch) => {
    dispatch({
      type: actions.TOGGLE_MODAL,
      data: { currentData, openModal: { isOpen, action } }
    });
  };
};

export const getRepresentantes = () => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const res = await api.get(endpoints.representantes);

    if (res.success) {
      dispatch({
        type: actions.GET_ALL,
        data: { isError: false, allData: res.data }
      });
    }
  } catch (error) {
    dispatch(setIsError(true));
  }
  dispatch(setIsLoading(false));
};

export const storeRepresentante = (payload) => async (dispatch) => {
  dispatch(setIsSubmitting(true));
  try {
    const res = await api.post(endpoints.representantes, payload);

    if (res.success) {
      dispatch({
        type: actions.STORE_REPRESENTANTE,
        data: {
          isError: false,
          newData: res.data,
          openModal: { isOpen: false, action: 'close' }
        }
      });
    }
  } catch (error) {
    dispatch(setIsError(true));
  }
  dispatch(setIsSubmitting(false));
};

export const updateRepresentante = (id, payload) => async (dispatch) => {
  dispatch(setIsSubmitting(true));
  try {
    const res = await api.put(`${endpoints.representantes}/${id}`, payload);
    if (res.success) {
      dispatch({
        type: actions.UPDATE_REPRESENTANTE,
        data: {
          isError: false,
          data: res.data,
          currentData: {},
          openModal: { isOpen: false, action: 'close' }
        }
      });
    }
  } catch (error) {
    dispatch(setIsError(true));
  }
  dispatch(setIsSubmitting(false));
};

export const deleteRepresentante = (data) => async (dispatch) => {
  dispatch(setIsDeleting(true));
  try {
    const res = await api.delete(`${endpoints.representantes}/${data.id}`);
    if (res.success) {
      dispatch({
        type: actions.DELETE_REPRESENTANTE,
        data: {
          data,
          isError: false,
          currentData: {},
          openModal: { isOpen: false, action: 'close' }
        }
      });
    }
  } catch (error) {
    dispatch(setIsError(true));
  }
  dispatch(setIsDeleting(false));
};

export const toggleActiveRepresentative = (id) => async (dispatch) => {
  dispatch(setIsDeleting(true));
  try {
    const res = await api.put(
      `${endpoints.representantes}/${id}/toggle-active`
    );

    if (res.success) {
      dispatch({
        type: actions.TOGGLE_ACTIVE,
        data: {
          data: res.data,
          isError: false,
          currentData: {},
          openModal: { isOpen: false, action: 'close' }
        }
      });
    }
  } catch (error) {
    dispatch(setIsError(true));
  }
  dispatch(setIsDeleting(false));
};
