import api from '@services/_api';

export const getAllDestaques = () => {
  return async (dispatch) => {
    const { data } = await api.get('api/v1/painel/destaques');

    dispatch({
      type: 'SET_DESTAQUES',
      data
    });
  };
};

export const createDestaque = (data) => {
  return async (dispatch) => {
    try {
      const { data: result } = await api.post('api/v1/painel/destaques', data, {
        beaders: {
          'Content-Type': 'multipart/form-data'
        }
      });

      dispatch({
        type: 'ADD_DESTAQUE',
        data: result
      });
    } catch (error) {
      //
    }
  };
};

export const updateDestaque = (data) => {
  return async (dispatch) => {
    try {
      const { data: result } = await api.post(
        `api/v1/painel/destaques/${data.get('id')}`,
        data
      );

      dispatch({
        type: 'UPDATE_DESTAQUE',
        data: result
      });
    } catch (error) {
      //
    }
  };
};

export const deleteDestaque = (id) => {
  return async (dispatch) => {
    await api.delete(`api/v1/painel/destaques/${id}`, id);

    dispatch({
      type: 'DELETE_DESTAQUE',
      data: id
    });
  };
};
