const initialState = {
  stepper: null,
  rowsPerPage: 10,

  search: '',

  currentData: {},
  openModal: {
    isOpen: false,
    action: 'create'
  },

  isDeleting: false,
  isError: false,
  isLoading: false,
  isSubmitting: false,

  allData: []
};

const initialAction = {
  type: null,
  data: {}
};

export const actions = {
  GET_ALL: 'VENDEDORES_GET_ALL',
  GET_ONE: 'VENDEDORES_GET_ONE',

  SET_CURRENT_DATA: 'VENDEDORES_SET_CURRENT_DATA',

  SET_IS_DELETING: 'VENDEDORES_SET_IS_DELETING',
  SET_IS_ERROR: 'VENDEDORES_SET_IS_ERROR',
  SET_IS_LOADING: 'VENDEDORES_SET_IS_LOADING',
  SET_IS_SUBMITTING: 'VENDEDORES_SET_IS_SUBMITTING',
  SET_LOADING_OPTIONS: 'VENDEDORES_SET_LOADING_OPTIONS',

  SET_ROWS_PER_PAGE: 'VENDEDORES_SET_ROWS_PER_PAGE',
  SET_SEARCH: 'VENDEDORES_SET_SEARCH',
  SET_STEPPER: 'VENDEDORES_SET_SETPPER',

  STORE: 'VENDEDORES_STORE',
  UPDATE: 'VENDEDORES_UPDATE',
  DELETE: 'VENDEDORES_DELETE',

  TOGGLE_ACTIVE: 'VENDEDORES_TOGGLE_ACTIVE',
  TOGGLE_MODAL: 'VENDEDORES_TOGGLE_MODAL'
};

function setStepper(state, action) {
  const { stepper = null } = action.data;
  return { ...state, stepper };
}

const storeData = (state = initialState, action = initialAction) => {
  const { newData, ...rest } = action.data;

  const newState = { ...state, ...rest };

  newState.allData.push(newData);

  return newState;
};

const updateData = (state = initialState, action = initialAction) => {
  const { data, ...rest } = action.data;
  const newState = { ...state, ...rest };

  newState.allData = newState.allData.map((item) => {
    return item.id === data.id ? { ...item, ...data } : item;
  });

  return newState;
};

const deleteData = (state = initialState, action = initialAction) => {
  const { data, ...rest } = action.data;
  const newState = { ...state, ...rest };

  const index = newState.allData.findIndex(({ id }) => id === data.id);

  newState.allData.splice(index, 1);

  return newState;
};

const reducer = (state = initialState, action = initialAction) => {
  switch (action.type) {
    case actions.GET_ALL:
      return { ...state, ...action.data };

    case actions.GET_ONE:
      return { ...state, ...action.data };

    case actions.SET_CURRENT_DATA:
      return { ...state, ...action.data };

    case actions.SET_IS_DELETING:
      return { ...state, ...action.data };

    case actions.SET_IS_ERROR:
      return { ...state, ...action.data };

    case actions.SET_IS_LOADING:
      return { ...state, ...action.data };

    case actions.SET_LOADING_OPTIONS:
      return { ...state, ...action.data };

    case actions.SET_IS_SUBMITTING:
      return { ...state, ...action.data };

    case actions.SET_ROWS_PER_PAGE:
      return { ...state, ...action.data };

    case actions.SET_SEARCH:
      return { ...state, ...action.data };

    case actions.SET_STEPPER:
      return setStepper(state, action);

    case actions.STORE:
      return storeData(state, action);

    case actions.UPDATE:
      return updateData(state, action);

    case actions.DELETE:
      return deleteData(state, action);

    case actions.TOGGLE_ACTIVE:
      return updateData(state, action);

    case actions.TOGGLE_MODAL:
      return { ...state, ...action.data };

    default:
      return state;
  }
};

export * from './actions';

export default reducer;
