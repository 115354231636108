import {
  Heart,
  Home,
  User,
  Image,
  DollarSign,
  List,
  HardDrive,
  HelpCircle,
  Smartphone,
  Box,
  TrendingUp,
  Shield
} from 'react-feather';
import { FaUserTie } from 'react-icons/fa';
import { IoBusiness } from 'react-icons/io5';
import { RiCoupon3Line, RiRefund2Fill, RiTeamFill } from 'react-icons/ri';

const navigation = [
  {
    id: 'home',
    title: 'Página Inicial',
    icon: <Home size={20} />,
    navLink: '/home'
  },
  { header: 'SOLICITAÇÕES' },
  {
    id: 'clientes',
    title: 'Clientes',
    icon: <Heart size={20} />,
    navLink: '/clientes'
  },
  {
    id: 'reembolsos',
    title: 'Reembolsos',
    icon: <RiRefund2Fill size={12} />,
    navLink: '/reembolsos'
  },
  { header: 'GERAL' },
  {
    id: 'safes',
    title: 'Proteção',
    navLink: '/protecao',
    icon: <Shield size={20} />
  },
  {
    id: 'planos',
    title: 'Planos',
    icon: <DollarSign size={20} />,
    children: [
      {
        id: 'planos',
        title: 'Planos',
        icon: <Box size={12} />,
        navLink: '/planos'
      },
      {
        id: 'pacotes',
        title: 'Modelos',
        icon: <Smartphone size={12} />,
        navLink: '/planos/modelos'
      },
      {
        id: 'Cobertura',
        title: 'Coberturas',
        icon: <List size={12} />,
        navLink: '/planos/cobertura'
      },
      {
        id: 'memoria',
        title: 'Memórias',
        icon: <HardDrive size={12} />,
        navLink: '/planos/memoria'
      }
    ]
  },
  {
    id: 'destaques',
    title: 'Destaques',
    icon: <Image size={20} />,
    navLink: '/destaques'
  },
  {
    id: 'cupons',
    title: 'Cupons',
    icon: <RiCoupon3Line size={20} />,
    navLink: '/cupons'
  },
  { header: 'COMERCIAL' },
  {
    id: 'representantes',
    title: 'Representantes',
    icon: <FaUserTie size={12} />,
    navLink: '/representantes'
  },
  {
    id: 'empresas',
    title: 'Empresas',
    icon: <IoBusiness size={12} />,
    navLink: '/empresas'
  },
  {
    id: 'vendedores',
    title: 'Vendedores',
    icon: <RiTeamFill size={12} />,
    navLink: '/vendedores'
  },
  { header: 'ADMINISTRATIVO' },
  {
    id: 'usuarios',
    title: 'Usuários',
    icon: <User size={20} />,
    navLink: '/usuarios'
  },
  {
    id: 'Notificacoes',
    title: 'Notificações',
    icon: <Smartphone size={12} />,
    navLink: '/notificacoes'
  },
  {
    id: 'ajuda',
    title: 'Ajuda',
    icon: <HelpCircle size={12} />,
    navLink: '/ajuda'
  },
  {
    id: 'reports',
    title: 'Relatórios',
    icon: <TrendingUp size={12} />,
    navLink: '/relatorios'
  }
];

export default navigation;
