/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */

// ** Import Axios
import axios from 'axios';

// ** Import React Toastify
import { toast } from 'react-toastify';

// ** Import API Config
import configs from '@configs/apiConfig';

// ** Import Redux Store
import { store } from '@store';

// ** Import Redux Actions List From Auth Reducers
import { actions } from '@features/auth';

// ** Import History
import history from '@src/history';

class Api {
  // ** API Config
  apiConfig = { ...configs };

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // ** For Refreshing Token
  subscribers = [];

  constructor() {
    // ** Create Axios instance
    this.useAxios = axios.create({
      baseURL: this.apiConfig.apiURL
    });

    // ** Request Interceptor
    this.useAxios.interceptors.request.use(
      (config) => this.interceptorRequestConfig(config),
      (error) => this.interceptorRequestError(error)
    );

    // ** Add request/response interceptor
    this.useAxios.interceptors.response.use(
      (response) => this.interceptorResponseSuccess(response),
      (error) => this.interceptorResponseError(error)
    );
  }

  /**
   *
   * @param {import('axios').AxiosRequestConfig} config
   */
  interceptorRequestConfig(config) {
    const newConfig = config;

    // ** Get token from localStorage
    const accessToken = this.getToken();

    // ** If token is present add it to request's Authorization Header
    if (accessToken) {
      newConfig.headers.Authorization = `${this.apiConfig.tokenType} ${accessToken}`;
    }

    return newConfig;
  }

  interceptorRequestError(error) {
    return Promise.reject(error);
  }

  /**
   *
   * @param {import('axios').AxiosResponse} response
   */
  interceptorResponseSuccess(response) {
    const newResponse = { ...response, success: true };

    const { data } = newResponse;

    if (data.status && data.status === 'nook') {
      newResponse.success = false;

      if (data.msg) toast.error(data.msg);

      if (data.errors) data.errors.forEach((error) => toast.error(error));
    }

    if (data.status && data.status === 'ok') {
      newResponse.success = true;
      if (data.msg) toast.success(data.msg);
    }

    if (data.message) toast.success(data.message);
    if (data.data) newResponse.data = data.data;

    return newResponse;
  }

  /**
   *
   * @param {import('axios').AxiosError} error
   */
  interceptorResponseError(error) {
    const newError = { ...error, success: false };

    if (newError.response) {
      const { data, status } = newError.response;

      // Check for an error message
      if (data.error) toast.error(data.error);

      // Check for an array with error messages
      if (data?.errors) {
        (data?.errors ?? []).forEach((error) => toast.error(error));
      }

      if (status === 401) {
        store.dispatch({ type: actions.LOGOUT });

        localStorage.removeItem(this.apiConfig.storageUserKeyName);
        localStorage.removeItem(this.apiConfig.storageTokenKeyName);

        history.push('/login');

        toast.error('Sessão expirada. Por favor, faça login novamente.', {
          toastId: 'session_expired'
        });
      }
    }

    if (newError.request) console.error(newError.request);
    else console.error('Error Message', newError.message);

    console.error('Error Config', newError.config);

    return Promise.reject(newError);
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return localStorage.getItem(this.apiConfig.storageTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem(this.apiConfig.storageTokenKeyName, value);
  }

  getRefreshToken() {
    return localStorage.getItem(this.apiConfig.storageRefreshTokenKeyName);
  }

  setRefreshToken(value) {
    localStorage.getItem(this.apiConfig.storageRefreshTokenKeyName, value);
  }

  async refreshToken() {
    const refreshEndpoint = this.apiConfig.endpoints.refreshToken;

    this.isAlreadyFetchingAccessToken = true;

    const res = await this.useAxios.post(refreshEndpoint, {
      refreshToken: this.getRefreshToken()
    });

    if (res.success) {
      this.isAlreadyFetchingAccessToken = false;

      // ** Update accessToken in localStorage
      this.setToken(res.data.accessToken);
      this.setRefreshToken(res.data.refreshToken);
      this.onAccessTokenFetched(res.data.accessToken);
    }
  }

  /**
   *
   * @param {import('axios').AxiosRequestConfig} request
   */
  retryOriginalRequest(request) {
    const originalRequest = request;

    return new Promise((resolve) => {
      this.addSubscriber(() => {
        const authorization = `${this.apiConfig.tokenType} ${this.getToken()}`;
        originalRequest.headers.Authorization = authorization;
        resolve(this.useAxios(originalRequest));
      });
    });
  }
}

export const { endpoints } = configs;

export default new Api().useAxios;
