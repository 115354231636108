import themeConfig from '@src/configs/themeConfig';

const Footer = () => {
  return (
    <div className="d-flex justify-content-between">
      <p className="clearfix mb-0">
        <span className="float-md-left d-block d-md-inline-block mt-25">
          Copyright © {new Date().getFullYear()}{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.7bis.com.br"
          >
            7bis
          </a>
          <span className="d-none d-sm-inline-block">
            , Todos os direitos reservados.
          </span>
        </span>
      </p>
      <p>{`Versão ${themeConfig.app.appVersion}`}</p>
    </div>
  );
};

export default Footer;
