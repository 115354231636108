import { Suspense } from 'react';
import { ToastContainer } from 'react-toastify';

// ** Spinner (Splash Screen)
import Spinner from '@core/components/spinner/Fallback-spinner';

// ** Contexts
import { AuthProvider } from '@contexts/auth';
import { ThemeContext } from '@contexts/ThemeColors';

// ** Router Import
import Router from './router/Router';

export function App() {
  return (
    <AuthProvider>
      <ThemeContext>
        <Suspense fallback={<Spinner />}>
          <Router />
        </Suspense>

        <ToastContainer newestOnTop />
      </ThemeContext>
    </AuthProvider>
  );
}
