import { combineReducers } from 'redux';

/* eslint-disable */
const reducer = combineReducers({
  auth: require('./auth').default,
  /**
   * Empresas
   */
  companies: require('./companies').default,
  /**
   * Cupons
   */
  coupons: require('./coupons').default,
  /**
   * Clientes
   */
  customers: require('./customers').default,
  /**
   * Destaques
   */
  featured: require('./featured').default,
  /**
   * Ajuda
   */
  help: require('./help').default,
  /**
   * Seguros
   */
  insurances: require('./insurances').default,
  /**
   * Layout
   */
  layout: require('./layout').default,
  /**
   * Navbar
   */
  navbar: require('./navbar').default,
  /**
   * Notificações
   */
  notifications: require('./notifications').default,
  /**
   * Formas de pagamento
   */
  paymentMethods: require('./payment-methods').default,
  /**
   * Planos
   */
  plans: require('./plans').default,
  /**
   * Reembolsos
   */
  refunds: require('./refunds').default,
  /**
   * Relatórios
   */
  reports: require('./reports').default,
  /**
   * Representantes
   */
  representatives: require('./representatives').default,
  /**
   * Vendedores
   */
  sellers: require('./sellers').default,
  /**
   * Estatísticas
   */
  statistics: require('./statistics').default,
  /**
   * Usuários
   */
  users: require('./users').default
});
/* eslint-enable */

export default reducer;
