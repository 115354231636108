import api from '@services/_api';

import { actions } from '.';

export const fetchPaymentMethods = (params = {}) => async (dispatch) => {
  try {
    const res = await api.get('api/v1/payment-methods', { params });
    dispatch({ type: actions.ALL, data: res.data });
  } catch (error) {
    dispatch({ type: actions.ALL, data: [] });
  }
};
