import api, { endpoints } from '@services/_api';

const {
  planos: plansUrl,
  marcas: brandsUrl,
  modelos: modelsUrl,
  memorias: memoirsUrl,
  coberturas: coversUrl,
  aparelhos: devicesUrl
} = endpoints;

// ** Memória
export const getAllMemorias = () => async (dispatch) => {
  const { data: result } = await api.get(memoirsUrl);

  dispatch({ type: 'SET_MEMORIAS', data: result });
};

export const createMemoria = (data) => async (dispatch) => {
  const { data: result } = await api.post(memoirsUrl, data);

  dispatch({ type: 'ADD_MEMORIA', data: result });
};

export const deleteMemoria = (id) => async (dispatch) => {
  await api.delete(`${memoirsUrl}/${id}`);

  dispatch({ type: 'REMOVE_MEMORIA', data: id });
};

export const editMemoria = (data) => async (dispatch) => {
  const { data: result } = await api.put(`${memoirsUrl}/${data.id}`, data);

  dispatch({ type: 'UPDATE_MEMORIA', data: result });
};

// ** Coberturas
export const getAllCoberturas = () => async (dispatch) => {
  const { data: result } = await api.get(coversUrl);

  dispatch({ type: 'SET_COBERTURAS', data: result });
};

export const createCobertura = (data) => async (dispatch) => {
  const { data: result } = await api.post(coversUrl, data);

  dispatch({ type: 'ADD_COBERTURA', data: result });
};

export const deleteCobertura = (id) => async (dispatch) => {
  await api.delete(`${coversUrl}/${id}`);

  dispatch({ type: 'REMOVE_COBERTURA', data: id });
};

export const edtiCobertura = (data) => async (dispatch) => {
  const { data: result } = await api.put(`${coversUrl}/${data.id}`, data);

  dispatch({ type: 'UPDATE_COBERTURA', data: result });
};

// ** Marcas
export const getAllMarcas = () => async (dispatch) => {
  const { data: result } = await api.get(brandsUrl);

  dispatch({ type: 'SET_MARCAS', data: result });
};

export const createMarca = (data) => async (dispatch) => {
  const { data: result } = await api.post(brandsUrl, data);

  dispatch({ type: 'ADD_MARCA', data: result });
};

export const deleteMarca = (id) => async (dispatch) => {
  await api.delete(`${brandsUrl}/${id}`);

  dispatch({ type: 'REMOVE_MARCA', data: id });
};

export const editMarca = (data) => async (dispatch) => {
  const { data: result } = await api.put(`${brandsUrl}/${data.id}`, data);

  dispatch({ type: 'UPDATE_MARCA', data: result });
};

// ** Modelos
export const getAllModelos = () => async (dispatch) => {
  const { data: result } = await api.get(modelsUrl);

  dispatch({ type: 'SET_MODELOS', data: result });
};

export const createModelo = (data) => async (dispatch) => {
  const { data: result } = await api.post(modelsUrl, data);

  dispatch({ type: 'ADD_MODELO', data: result });
};

export const deleteModelo = (id) => async (dispatch) => {
  await api.delete(`${modelsUrl}/${id}`);

  dispatch({ type: 'REMOVE_MODELO', data: id });
};

export const editModelo = (data) => async (dispatch) => {
  const { data: result } = await api.put(`${modelsUrl}/${data.id}`, data);

  dispatch({ type: 'UPDATE_MODELO', data: result });
};

export const searchModelos = (query) => async (dispatch) => {
  dispatch({ type: 'SEARCH_MODELOS', data: query });
};

// ** Aparelhos
export const getAllAparelhos = () => async (dispatch) => {
  const { data: result } = await api.get(devicesUrl);

  dispatch({ type: 'SET_APARELHOS', data: result });
};

export const getAparelhosByModelo = (byModelo) => async (dispatch) => {
  const { data: result } = await api.get(devicesUrl, { params: { byModelo } });

  dispatch({ type: 'SET_APARELHOS', data: result });
};

export const createAparelho = (data) => async (dispatch) => {
  const { data: result } = await api.post(devicesUrl, data);

  dispatch({ type: 'ADD_APARELHO', data: result });
};

export const editAparelho = (data) => async (dispatch) => {
  const { data: result } = await api.put(`${devicesUrl}/${data.id}`, data);

  dispatch({ type: 'UPDATE_APARELHO', data: result });
};

export const deleteAparelho = (id) => async (dispatch) => {
  await api.delete(`${devicesUrl}/${id}`);

  dispatch({ type: 'REMOVE_APARELHO', data: id });
};

// ** Planos
export const getAllPlanos = () => async (dispatch) => {
  const { data: result } = await api.get(plansUrl);

  dispatch({ type: 'SET_PLANOS', data: result });
};

export const createPlano = (data) => async (dispatch) => {
  const { data: result } = await api.post(plansUrl, data);

  dispatch({ type: 'ADD_PLANO', data: result });
};

export const editPlano = (data) => async (dispatch) => {
  const { data: result } = await api.put(`${plansUrl}/${data.id}`, data);

  dispatch({ type: 'UPDATE_PLANO', data: result });
};

export const deletePlano = (id) => async (dispatch) => {
  await api.delete(`${plansUrl}/${id}`);

  dispatch({ type: 'REMOVE_PLANO', data: id });
};

export const searchPlano = (query) => (dispatch) => {
  dispatch({ type: 'SEARCH_PLANOS', data: query });
};

export const changeActive = (id, payload) => async (dispatch) => {
  const { data } = await api.put(`${plansUrl}/${id}/change-active`, payload);
  dispatch({ type: 'UPDATE_PLANO', data });
};

export const setRowsPerPage = (rowsPerPage) => (dispatch) => {
  dispatch({ type: 'PLANOS_SET_ROWS_PER_PAGE', data: { rowsPerPage } });
};
