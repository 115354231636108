/* eslint-disable no-console */
import api, { endpoints } from '@services/_api';
import { actions } from '.';

export const setIsDeleting = (isDeleting) => {
  return { type: actions.SET_IS_DELETING, data: { isDeleting } };
};

export const setIsError = (isError) => {
  return { type: actions.SET_IS_ERROR, data: { isError } };
};

export const setIsLoading = (isLoading) => {
  return { type: actions.SET_IS_LOADING, data: { isLoading } };
};

export const setIsSubmitting = (isSubmitting) => {
  return { type: actions.SET_IS_SUBMITTING, data: { isSubmitting } };
};

export const setCurrentData = (currentData) => (dispatch) => {
  dispatch({ type: actions.SET_CURRENT_DATA, data: { currentData } });
};

export const setRowsPerPage = (rowsPerPage) => (dispatch) => {
  dispatch({ type: actions.SET_ROWS_PER_PAGE, data: { rowsPerPage } });
};

export const setSearch = (search) => (dispatch) => {
  dispatch({ type: actions.SET_SEARCH, data: { search } });
};

/**
 *
 * @param {Boolean} isOpen
 * @param {'create'|'edit'|'delete'|'close'|'status-change'} action
 * @param {Object} currentData
 */
export const toggleModal = (isOpen, action = 'create', currentData = {}) => {
  return (dispatch) => {
    dispatch({
      type: actions.TOGGLE_MODAL,
      data: { currentData, openModal: { isOpen, action } }
    });
  };
};

export const getReembolsos = () => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const res = await api.get(endpoints.reembolsos);

    if (res.success) {
      dispatch({
        type: actions.GET_ALL,
        data: { isError: false, allData: res.data }
      });
    }
  } catch (error) {
    console.log(error);
    dispatch(setIsError(true));
  }
  dispatch(setIsLoading(false));
};

export const getReembolso = (id) => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const res = await api.get(`${endpoints.reembolsos}/${id}`);
    if (res.success) {
      dispatch({
        type: actions.GET_ONE,
        data: { isError: false, currentData: res.data }
      });
    }
  } catch (error) {
    console.log(error);
    dispatch(setIsError(true));
  }
  dispatch(setIsLoading(false));
};

export const storeReembolso = (payload) => async (dispatch) => {
  dispatch(setIsSubmitting(true));
  try {
    const res = await api.post(endpoints.reembolsos, payload);
    if (res.success) {
      dispatch({
        type: actions.STORE,
        data: {
          isError: false,
          newReembolso: res.data,
          openModal: { isOpen: false, action: 'close' }
        }
      });
    }
  } catch (error) {
    console.log(error);
    dispatch(setIsError(true));
  }
  dispatch(setIsSubmitting(false));
};

const updateDispatch = (data) => ({
  type: actions.UPDATE,
  data: {
    data,
    isError: false,
    currentData: {},
    openModal: { isOpen: false, action: 'close' }
  }
});

export const updateReembolso = (id, payload) => async (dispatch) => {
  dispatch(setIsSubmitting(true));
  try {
    const res = await api.post(`${endpoints.reembolsos}/${id}`, payload);
    if (res.success) dispatch(updateDispatch(res.data));
  } catch (error) {
    console.log(error);
    dispatch(setIsError(true));
  }
  dispatch(setIsSubmitting(false));
};

export const updateRefundStatus = (id, payload) => async (dispatch) => {
  dispatch(setIsSubmitting(true));
  try {
    const res = await api.put(`${endpoints.reembolsos}/${id}`, payload);
    if (res.success) dispatch(updateDispatch(res.data));
  } catch (error) {
    console.log(error);
    dispatch(setIsError(true));
  }
  dispatch(setIsSubmitting(false));
};

export const deleteReembolso = (reembolso) => async (dispatch) => {
  dispatch(setIsDeleting(true));
  try {
    const res = await api.delete(`${endpoints.reembolsos}/${reembolso.id}`);
    if (res.success) {
      dispatch({
        type: actions.DELETE,
        data: {
          reembolso,
          isError: false,
          currentData: {},
          openModal: { isOpen: false, action: 'close' }
        }
      });
    }
  } catch (error) {
    console.log(error);
    dispatch(setIsError(true));
  }
  dispatch(setIsDeleting(false));
};
