import React from 'react';
// ** React Imports
import ReactDOM from 'react-dom';

// ** Redux Imports
import { Provider } from 'react-redux';
import { store } from '@store';

// ** Ripple Button
import '@core/components/ripple-button';

// ** PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss';

// ** Core styles
import '@core/assets/fonts/feather/iconfont.css';
import '@core/scss/core.scss';
import '@assets/scss/style.scss';
import '@styles/base/pages/page-misc.scss';

// ** moment
import moment from 'moment';
import br from 'moment/locale/pt-br';

// ** Service Worker
import * as serviceWorker from './serviceWorker';

import { App } from './App';

moment.locale('pt-br', br);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
