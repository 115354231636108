/* eslint-disable no-console */
import api from '@services/_api';
import configs from '@configs/apiConfig';
import { actions } from '.';

const baseURL = '/api/v1/painel';

const { storageTokenKeyName, storageUserKeyName } = configs;

export const setIsError = (value) => {
  return (dispatch) => {
    dispatch({
      type: actions.SET_IS_ERROR,
      data: { isError: value }
    });
  };
};

export const setIsSubmitting = (value) => {
  return (dispatch) => {
    dispatch({
      type: actions.SET_IS_SUBMITTING,
      data: { isSubmitting: value }
    });
  };
};

// ** Handle User Login
export const handleLogin = (params) => {
  return async (dispatch) => {
    try {
      const res = await api.post(`${baseURL}/login`, params);

      dispatch({
        type: actions.LOGIN,
        data: {
          user: res.data.user,
          isAuthenticated: true,
          isError: false,
          isSubmitting: false
        }
      });

      // ** Add to user to localStorage
      localStorage.setItem(storageTokenKeyName, res.data.token);
      localStorage.setItem(storageUserKeyName, JSON.stringify(res.data.user));
    } catch (error) {
      console.log(error);
      dispatch({
        type: actions.SET_IS_ERROR,
        data: { isError: true, isSubmitting: false }
      });
    }
  };
};

// ** Handle User Logout
export const handleLogout = () => {
  return (dispatch) => {
    dispatch({ type: actions.LOGOUT });

    // ** Remove user from localStorage
    localStorage.removeItem(storageUserKeyName);
    localStorage.removeItem(storageTokenKeyName);
  };
};

export const setUser = (user, isAuthenticated) => (dispatch) => {
  dispatch({ type: actions.SET_USER, data: { user, isAuthenticated } });
};
